import { defineStore } from 'pinia';
import { ModalName } from '@/types';

interface ModalState {
  modalStack: ModalName[];
}

export const useModalStore = defineStore('modal', {
  state(): ModalState {
    return {
      modalStack: [],
    }
  },
  actions: {
    setModal(currentModal: ModalName | null) {
      if (currentModal) {
        this.modalStack.push(currentModal)
      } else {
        this.modalStack.pop();
      }
    }
  }
})