<template>
  <div class="tab">
    <div class="tab-content">
      <slot name="content"></slot>
    </div>
    <div class="tab-sidebar">
      <slot name="sidebar"></slot>
    </div>
  </div>
</template>
<style lang="scss">
.tab {
  display: flex;
  .tab-content {
    position: relative;
    padding-top: 0.4rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 2;
    @include imageCDN('images/main_tab/main_tab.png', 10.73rem, 6.81rem);
  }
  .tab-sidebar {
    position: relative;
    @include imageCDN('images/main_tab/right_tab_base.png', 3.15rem, 6.18rem);
    padding: 0.2rem;
    margin-top: 0.8rem;
    margin-left: -0.8rem;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }
}
</style>
