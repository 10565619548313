
import { defineComponent } from 'vue';
import { useModalStore } from '@/store';
import ErrorMessage from './ErrorMessage.vue';
import EventRules from './EventRules.vue';
import ConfirmJoin from './ConfirmJoin.vue';
import NotifyJoin from './NotifyJoin.vue';
import NotifyInviteSent from './NotifyInviteSent.vue';
import NotifyClaimReward from './NotifyClaimReward.vue';
import NotifyCopy from './NotifyCopy.vue';
import ConfirmChangeName from './ConfirmChangeName.vue';
import SerialCode from '../SerialCodes.vue';

export default defineComponent({
  components: {
    ErrorMessage,
    EventRules,
    ConfirmJoin,
    NotifyJoin,
    NotifyInviteSent,
    NotifyClaimReward,
    NotifyCopy,
    ConfirmChangeName,
    SerialCode,
  },

  setup() {
    const { modalStack } = useModalStore();

    return { modalStack };
  },
});
