function getParam(paramName: string, url: string = window.location.href) {
  const name = paramName.replace(/[[]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function camelizeStr(str: string): string {
  return str.replace(/[_.-](\w|$)/g, (_, x) => x.toUpperCase());
}

function snakifyStr(str: string): string {
  return str.replace(/(?:^|\.?)([A-Z])/g, (_, x) => `_${x.toLowerCase()}`);
}

type ConvertFunc = (k: string) => string;
export type AnyJson =
  | boolean
  | number
  | string
  | null
  | undefined
  | JsonArray
  | JsonMap;
interface JsonMap {
  [key: string]: AnyJson;
}
type JsonArray = Array<AnyJson>;

function convertCase(convertFunc: ConvertFunc) {
  function converter(thing: AnyJson): AnyJson {
    if (Array.isArray(thing)) {
      return thing.map((i) => converter(i));
    }
    if (thing && typeof thing === 'object') {
      const newObj: JsonMap = {};
      Object.keys(thing).forEach((k: string) => {
        newObj[convertFunc(k)] = converter(thing[k]);
      });
      return newObj;
    }
    return thing;
  }
  return converter;
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function checkInGameForUD() {
  return window.navigator.userAgent.includes('INTLBrowser');
}

export function copyText(content: string): void {
  const text = document.createElement('input');
  text.value = content;
  document.body.appendChild(text);
  text.select();
  text.setSelectionRange(0, content.length);
  document.execCommand('copy');
  document.body.removeChild(text);
}

export const checkDevice = () => {
  // 设备检测
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1;
  if (isAndroid) {
    return 'android';
  }
  const isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if (isiOS) {
    return 'ios';
  }
  if (
    /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) ||
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  ) {
    return 'ios';
  } else if (/(Android)/i.test(navigator.userAgent)) {
    return 'android';
  }
  return 'other';
};

export default {
  getParam,
  camelizeStr,
  snakifyStr,
  delay,
  camelizeKeys: convertCase(camelizeStr),
  snakifyKeys: convertCase(snakifyStr),
};
