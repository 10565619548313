import axios, { AxiosResponse } from 'axios';
import utils, { AnyJson } from '@/utils';

const { pathname } = window.location;
// store access_token
const encodeparam = utils.getParam('encodeparam');
const token = utils.getParam('access_token');
const partition = utils.getParam('partition');
const areaid = utils.getParam('area_id');

const useUrlParam =
  utils.getParam('access_token') || utils.getParam('encodeparam');
if (useUrlParam) {
  if (utils.getParam('access_token')) {
    localStorage.removeItem('ENCODE_PARAM');
  }
  if (utils.getParam('encodeparam')) {
    localStorage.removeItem('TOKEN');
  }
}

if (token) localStorage.setItem('TOKEN', token);
if (encodeparam) localStorage.setItem('ENCODE_PARAM', encodeparam);
if (partition) localStorage.setItem('PARTITION', partition);
if (areaid) localStorage.setItem('AREA_ID', areaid);

const API = '/api';
const TOKEN = localStorage.getItem('TOKEN');
const ENCODE_PARAM = localStorage.getItem('ENCODE_PARAM');
const PARTITION = localStorage.getItem('PARTITION');
const AREA_ID = localStorage.getItem('AREA_ID');

// prevent access by copy url
if (process.env.NODE_ENV === 'production') {
  window.history.replaceState(
    '',
    '',
    // partition && areaid
    //   ? `${pathname}?partition=${partition}&area_id=${areaid}`
    //   :
    `${pathname}`,
  );
}

const headers: any = {
  'Content-Type': 'application/json',
  'Utm-Source': 'plt',
  Partition: PARTITION,
  'Area-Id': AREA_ID,
};
if (ENCODE_PARAM) headers.encodeparam = ENCODE_PARAM;
else headers['Access-Token'] = TOKEN;

export const instance = axios.create({
  baseURL: API,
  headers,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  withCredentials: true,
});

// Add a response interceptor
/* eslint-disable @typescript-eslint/no-explicit-any */
instance.interceptors.response.use(
  (response: any) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (process.env.NODE_ENV === 'development') {
      console.log(response);
    }
    return utils.camelizeKeys(response.data) as unknown as AxiosResponse;
  },
  (error: Error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);
instance.interceptors.request.use((request) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(request);
  }
  return request;
});

export default function <T>(method: string, url: string, data: AnyJson = null) {
  const requestType = method.toLowerCase();
  const normalizedParams = utils.snakifyKeys(data);

  switch (requestType) {
    case 'get':
      return instance.get(url, { params: normalizedParams }) as Promise<T>;
    case 'put':
      return instance.put(url, normalizedParams) as Promise<T>;
    case 'delete':
      return instance.delete(url, { params: normalizedParams }) as Promise<T>;
    case 'post':
      return instance.post(url, normalizedParams) as Promise<T>;
    default:
      throw new Error(
        `Unknown request method of ${method}! You might have a typo.`,
      );
  }
}
