import { defineStore } from 'pinia';
import { ModalEnum, ErrorMessageResponse } from '@/types';
import { useModalStore } from '@/store';

export const useGeneralStore = defineStore('general', {
  state() {
    return {
      isLoading: false,
      isFreezing: false,
      isMaintenance: false,
      errorMessage: '',
    };
  },

  actions: {
    setIsLoading(isLoading: boolean) {
      this.isLoading = isLoading;
    },
    setIsFreezing(isFreezing: boolean) {
      this.isFreezing = isFreezing;
    },
    setIsMaintenance(isMaintenance: boolean, errorMessage: string) {
      this.isMaintenance = isMaintenance;
      this.errorMessage = errorMessage;
    },
    handleError({ error }: ErrorMessageResponse) {
      const { setModal } = useModalStore();
      this.errorMessage = error;

      setModal(ModalEnum.ERROR_MESSAGE);
    },
  },
});
