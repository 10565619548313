export enum ModalTypeEnum {
  BASIC = 'basic',
  LARGE = 'large',
}

export enum RelationEnum {
  NOT_FRIEND = 1,
  PENDING = 2,
  FRIEND = 3,
}
export enum PlatformEnum {
  FB = 1,
  LINE = 2,
  TWITTER = 3,
}

export enum ModalEnum {
  ERROR_MESSAGE = 'ErrorMessage',
  NOTIFY_REDEEM_PRIZE = 'NotifyRedeemPrize',
  EVENT_RULES = 'EventRules',
  CONFIRM_JOIN = 'ConfirmJoin',
  NOTIFY_JOIN = 'NotifyJoin',
  NOTIFY_INVITE_SENT = 'NotifyInviteSent',
  NOTIFY_CLAIM_REWARD = 'NotifyClaimReward',
  NOTIFY_COPY = 'NotifyCopy',
  CONFIRM_CHANGE_NAME = 'ConfirmChangeName',
}

export type ModalName = `${ModalEnum}`;

export type TeamMember = {
  playerId: number;
  name: string;
  img: string;
  relation: number;
};

export type RewardDetail = {
  name: string;
  img: string;
  num: number;
};
export type RewardPackage = {
  img: string;
  details: RewardDetail[];
};
export type Task = {
  taskId?: number;
  description: string;
  progress: number;
  target: number;
  achieved: boolean;
  claimed?: boolean;
  reward?: RewardPackage;
  points?: number;
};
export type Mission = {
  title: string;
  tasks: Task[];
};

export interface ErrorMessageResponse {
  error: string;
}

export interface PlayInfoResponse {
  playerInfo: {
    level: number;
    name: string;
    joinAsRookie: boolean;
    showConsent: boolean;
    serialCode: string;
  };
  config: {
    rookieLevel: number;
    captainLevel: number;
    rookieRewards: RewardDetail[];
    teamNameLong: number;
  };
}
export interface RookieTeamResponse {
  teamInfo: {
    teamId: number;
    teamName: string;
    captain: TeamMember;
    members: TeamMember[];
  };
}
export interface RookieMissionResponse {
  levelMission: Mission;
  pveMission: Mission;
  topupMission: Mission;
}

export interface ClaimRewardResponse {
  reward: RewardPackage;
}

export interface CaptainTeamResponse {
  teamId: number;
  teamName: string;
  sharingCode: string;
  platforms: number[];
  onelink: string;
  codePrefix: string;
  captain: {
    name: string;
    img: string;
  };
  ableChangeName: boolean;
  members: TeamMember[];
}

export interface CaptainMissionResponse {
  milestone: {
    progress: number;
    breakpoints: {
      target: number;
      achieved: boolean;
      claimed: boolean;
      reward: RewardPackage;
    }[];
  };
  levelMission: Mission;
  pveMission: Mission;
  topupMission: Mission;
}

export interface ClaimMilestoneResponse {
  reward: RewardPackage;
}

export interface TeamProgressResponse {
  members: {
    name: string;
    levelMission: {
      progress: number;
      target: number;
    };
    pveMission: {
      progress: number;
      target: number;
    };
    topupMission: {
      progress: number;
      target: number;
    };
  }[];
}
