import { defineStore } from 'pinia';
import services from '@/services';
import { useModalStore } from '@/store';
import {
  ModalEnum,
  TeamMember,
  Mission,
  RewardPackage,
  RelationEnum,
} from '@/types';
import { apiHandler } from './utils';

interface GameState {
  inviterTeamName: string;
  rookieTeamId: number;
  rookieTeamMember: TeamMember[];
  rookieMissions: Mission[];
  captainTeamId: number;
  captainTeamName: string;
  captainTeamMember: TeamMember[];
  captainMission: Mission[];
  captainMilestone: {
    progress: number;
    breakpoints: {
      target: number;
      achieved: boolean;
      claimed: boolean;
      reward: RewardPackage;
    }[];
  };
  captainTeamProgress: {
    name: string;
    levelMission: {
      progress: number;
      target: number;
    };
    pveMission: {
      progress: number;
      target: number;
    };
    topupMission: {
      progress: number;
      target: number;
    };
  }[];
  ableChangeName: boolean;
  sharePlatform: number[];
  sharingCode: string;
  onelink: string;
  codePrefix: string;

  inputSharingCode: string;
  claimedReward: RewardPackage;
  inputTeamName: string;
}

export const useGameStore = defineStore('game', {
  state(): GameState {
    return {
      inviterTeamName: '',
      rookieTeamId: 0,
      rookieTeamMember: [],
      rookieMissions: [],
      captainTeamId: 0,
      captainTeamName: '',
      captainTeamMember: [],
      captainMission: [],
      captainMilestone: {
        progress: 0,
        breakpoints: [
          {
            target: 10,
            achieved: false,
            claimed: false,
            reward: {
              img: '',
              details: [],
            },
          },
        ],
      },
      captainTeamProgress: [],
      ableChangeName: false,
      sharePlatform: [],
      sharingCode: '',
      codePrefix: '',
      onelink: '',

      inputSharingCode: '',
      claimedReward: { img: '', details: [] },
      inputTeamName: '',
    };
  },
  actions: {
    async getTeamName() {
      const response = await apiHandler(() =>
        services.getTeamName(this.inputSharingCode),
      );

      if (response) {
        const { setModal } = useModalStore();
        this.inviterTeamName = response.teamName;

        setModal(ModalEnum.CONFIRM_JOIN);
      }
    },
    async postJoinTeam() {
      const response = await apiHandler(() =>
        services.postJoinTeam(this.inputSharingCode),
      );

      if (response) {
        const { setModal } = useModalStore();

        setModal(ModalEnum.NOTIFY_JOIN);
      }
    },
    async getRookieTeam() {
      const response = await apiHandler(services.getRookieTeam);

      if (response) {
        const {
          teamInfo: { teamId, teamName, captain, members },
        } = response;

        this.$patch({
          rookieTeamId: teamId,
          inviterTeamName: teamName,
          rookieTeamMember: [captain, ...members],
        });
      }
    },
    async postAddFriend(playerId: number, isRookieTeam: boolean) {
      const response = await apiHandler(() =>
        services.postAddFriend({
          playerId,
          teamId: isRookieTeam ? this.rookieTeamId : this.captainTeamId,
        }),
      );

      if (response) {
        const { setModal } = useModalStore();

        setModal(ModalEnum.NOTIFY_INVITE_SENT);
        this[isRookieTeam ? `rookieTeamMember` : `captainTeamMember`] = this[
          isRookieTeam ? `rookieTeamMember` : `captainTeamMember`
        ].map((member) => {
          if (member.playerId === playerId) {
            return {
              ...member,
              relation: RelationEnum.PENDING,
            };
          }
          return member;
        });
      }
    },
    async getRookieMission() {
      const response = await apiHandler(services.getRookieMission);

      if (response) {
        const { levelMission, pveMission, topupMission } = response;

        this.rookieMissions = [levelMission, pveMission, topupMission];
      }
    },
    async postClaimReward(taskId: number, index: number) {
      const response = await apiHandler(() =>
        services.postClaimReward({ taskId, teamId: this.rookieTeamId }),
      );

      if (response) {
        const { reward } = response;
        this.rookieMissions[index].tasks = this.rookieMissions[index].tasks.map(
          (task) => {
            if (task.taskId === taskId) {
              return {
                ...task,
                claimed: true,
              };
            }
            return task;
          },
        );
        this.$patch({
          claimedReward: reward,
        });
        const { setModal } = useModalStore();

        setModal(ModalEnum.NOTIFY_CLAIM_REWARD);
      }
    },
    async getCaptainTeam() {
      const response = await apiHandler(services.getCaptainTeam);

      if (response) {
        const {
          teamId,
          teamName,
          sharingCode,
          platforms,
          onelink,
          codePrefix,
          captain,
          members = [],
          ableChangeName,
        } = response;

        this.$patch({
          captainTeamId: teamId,
          captainTeamName: teamName,
          inputTeamName: teamName,
          sharePlatform: platforms,
          captainTeamMember: [captain, ...members],
          sharingCode,
          codePrefix,
          onelink,
          ableChangeName,
        });
      }
    },
    async postChangeTeamName() {
      const response = await apiHandler(() =>
        services.postChangeTeamName({
          teamId: this.captainTeamId,
          old: this.captainTeamName,
          new: this.inputTeamName,
        }),
      );

      if (response) {
        this.captainTeamName = this.inputTeamName;
        this.ableChangeName = false;
      }
    },
    async getCaptainMission() {
      const response = await apiHandler(services.getCaptainMission);

      if (response) {
        const { milestone, levelMission, pveMission, topupMission } = response;

        this.$patch({
          captainMilestone: milestone,
          captainMission: [levelMission, pveMission, topupMission],
        });
      }
    },
    async postClaimMilestone(target: number) {
      const response = await apiHandler(() =>
        services.postClaimMilestone({ target, teamId: this.captainTeamId }),
      );

      if (response) {
        const { setModal } = useModalStore();
        this.captainMilestone.breakpoints =
          this.captainMilestone.breakpoints.map((breakpoint) => {
            if (breakpoint.target === target) {
              return {
                ...breakpoint,
                claimed: true,
              };
            }
            return breakpoint;
          });

        this.claimedReward = response.reward;

        setModal(ModalEnum.NOTIFY_CLAIM_REWARD);
      }
    },
    async getTeamProgress() {
      const response = await apiHandler(services.getTeamProgress);

      if (response) {
        const { members } = response;

        this.captainTeamProgress = members;
      }
    },
  },
});
