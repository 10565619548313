import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import App from '@/App.vue';
import i18n from '@/i18n';
import '@/preloader';
import '@/styles/global.scss';
import setupLayout from '@/layout';
setupLayout();

const app = createApp(App);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://1f221ef948574f74aa4b877d1d22d603@pub.sentry.web.garenanow.com/175',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    integrations: [
      new Integrations.Vue({ Vue: app as any, attachProps: true }),
    ],
  });
}

app.use(createPinia()).use(i18n);
app.mount('#app');
