import { ErrorMessageResponse } from '@/types';
import { useGeneralStore } from '@/store';

const MaintenanceStatus = [
  'ERROR__NOT_EVENT_PERIOD',
  'ERROR__UNDER_MAINTENANCE',
];

let timer: ReturnType<typeof setTimeout>;

export async function apiHandler<T>(
  api: () => Promise<T | ErrorMessageResponse>,
  isSkipLoading = false,
): Promise<T | null> {
  const { setIsLoading, handleError, setIsMaintenance, setIsFreezing } =
    useGeneralStore();

  setIsFreezing(true);
  if (!isSkipLoading) {
    timer = setTimeout(() => setIsLoading(true), 500);
  }
  try {
    const response = await api();

    if (response && typeof response === 'object' && 'error' in response) {
      if (MaintenanceStatus.indexOf(response.error) > -1) {
        setIsMaintenance(true, response.error);
      } else {
        handleError(response); // expected error
      }
    } else {
      return response;
    }
  } catch (err) {
    handleError({ error: 'ERROR__SERVER_ERROR' }); // unexpected error
  } finally {
    if (!isSkipLoading) setIsLoading(false);
    clearTimeout(timer);
    setIsFreezing(false);
  }

  return null;
}
