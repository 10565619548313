<template>
  <div class="loading" />
</template>

<style lang="scss">
.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2001;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  &:after {
    position: absolute;
    content: '';
    top: 3rem;
    // left: 50%;
    // transform: translate(-50%, -50%);
    border: 6px solid #ffffff8f;
    width: 50px;
    height: 50px;
    border-top-color: #c4c4c4;
    border-radius: 50%;
    animation: rotation 0.8s ease-in-out infinite;
    box-sizing: border-box;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
