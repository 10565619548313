import { defineStore } from 'pinia';
import { RewardDetail } from '@/types';

interface TooltipState {
  RewardTooltip: {
    isShow: boolean;
    rewards: RewardDetail[];
    x: number;
    y: number;
    isTop: boolean;
  };
  FloatText: {
    isShow: boolean;
    text: string;
    x: number;
    y: number;
  };
}

export const useTooltipStore = defineStore('tooltip', {
  state(): TooltipState {
    return {
      RewardTooltip: {
        isShow: false,
        rewards: [],
        x: 0,
        y: 0,
        isTop: false,
      },
      FloatText: {
        isShow: false,
        text: '',
        x: 0,
        y: 0,
      },
    };
  },
  actions: {
    showRewardTooltip(
      isShow = false,
      rewards: RewardDetail[] = [],
      { x = 0, y = 0 },
      isTop = false,
    ) {
      this.RewardTooltip = {
        isShow,
        rewards,
        x,
        y,
        isTop,
      };
    },
    hideRewardTooltip() {
      this.RewardTooltip = {
        ...this.RewardTooltip,
        isShow: false,
        rewards: [],
      };
    },
    showFloatText(isShow = false, text = '', { x = 0, y = 0 }) {
      this.FloatText = {
        isShow,
        text,
        x,
        y,
      };
    },
    hideFloatText() {
      this.FloatText = {
        ...this.FloatText,
        isShow: false,
        text: '',
      };
    },
  },
});
