import api from './api';
import {
  PlayInfoResponse,
  RookieTeamResponse,
  RookieMissionResponse,
  ClaimRewardResponse,
  CaptainTeamResponse,
  CaptainMissionResponse,
  ClaimMilestoneResponse,
  TeamProgressResponse,
} from '@/types';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const mock = require('./mock').default;
  mock();
}

export default {
  getPlayerInfo: () => api<PlayInfoResponse>('get', '/player-info'),
  getTeamName: (sharingCode: string) =>
    api<{ teamName: string }>('get', '/teamname', { sharingCode }),
  postJoinTeam: (sharingCode: string) =>
    api<{}>('post', '/join-team', { sharingCode }),
  getRookieTeam: () => api<RookieTeamResponse>('get', '/rookie-team'),
  postAddFriend: ({ playerId, teamId }: { playerId: number; teamId: number }) =>
    api<{}>('post', '/add-friend', { teamId, playerId }),
  getRookieMission: () => api<RookieMissionResponse>('get', '/rookie-mission'),
  postClaimReward: (data: { taskId: number; teamId: number }) =>
    api<ClaimRewardResponse>('post', '/claim-reward', data),
  getCaptainTeam: () => api<CaptainTeamResponse>('get', '/captain-team'),
  postChangeTeamName: (data: { teamId: number; old: string; new: string }) =>
    api<{}>('post', '/change-teamname', data),
  getCaptainMission: () =>
    api<CaptainMissionResponse>('get', '/captain-mission'),
  postClaimMilestone: (data: { target: number; teamId: number }) =>
    api<ClaimMilestoneResponse>('post', 'claim-milestone', data),
  getTeamProgress: () => api<TeamProgressResponse>('get', '/team-progress'),
  postConsent: (isConsented: boolean) =>
    api<{}>('post', '/consent', {
      isConsented,
    }),
};
