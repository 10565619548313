import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SerialCode = _resolveComponent("SerialCode")!

  return (_openBlock(), _createBlock(_TransitionGroup, {
    name: "modal",
    appear: ""
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modalStack, (modal, i) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent(modal), { key: i }))
      }), 128)),
      (_ctx.modalStack.length > 0)
        ? (_openBlock(), _createBlock(_component_SerialCode, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}