import { defineStore } from 'pinia';
import services from '@/services';
import { useGeneralStore } from '@/store';
import { RewardDetail } from '@/types';
// import { checkInGameForUD } from '@/utils';
import { apiHandler } from './utils';

interface ProfileState {
  hasJoinTeam: boolean;
  currentLevel: number;
  rookieLevel: number;
  captainLevel: number;
  rookieRewards: RewardDetail[];
  teamNameLong: number;
  showConsent: boolean;
  serialCode: string;
}

export const useProfileStore = defineStore('profile', {
  state(): ProfileState {
    return {
      hasJoinTeam: false,
      currentLevel: 0,
      rookieLevel: 1,
      captainLevel: 2,
      rookieRewards: [],
      teamNameLong: 10,
      showConsent: false,
      serialCode: '',
    };
  },

  actions: {
    updateHasJoinTeam() {
      this.hasJoinTeam = true;
    },
    async getPlayerInfo() {
      // if (!checkInGameForUD()) {
      if (
        !localStorage.getItem('TOKEN') &&
        !localStorage.getItem('ENCODE_PARAM')
      ) {
        const { handleError } = useGeneralStore();

        handleError({ error: 'ERROR__NOT_INGAME' });
        return;
      }
      const response = await apiHandler(services.getPlayerInfo);

      if (response) {
        const {
          playerInfo: { level, joinAsRookie, showConsent, serialCode },
          config: { rookieLevel, captainLevel, rookieRewards, teamNameLong },
        } = response;
        this.$patch({
          hasJoinTeam: joinAsRookie,
          currentLevel: level,
          rookieLevel,
          captainLevel,
          rookieRewards,
          showConsent,
          teamNameLong,
          serialCode,
        });
      }
    },
    async postConsent(isConsented: boolean) {
      const response = await apiHandler(() =>
        services.postConsent(isConsented),
      );
      if (response) {
        this.showConsent = false;
      }
    },
  },
});
